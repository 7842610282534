import React from 'react'
import path from '../product-data/path.json'
import Header from '../components/Header'
import Menu from '../components/Menu'
import Layout from '../components/layout'
import Footer from '../components/Footer'
import ContentView from '../components/Product/ContentView'
import RentBox from '../components/Product/RentBox'
import ProcessBox from '../components/Product/ProcessBox'
import BoxSection from '../components/Product/BoxSection'
import CatalogView from '../components/Product/CatalogView'
import BreadCrumb from '../components/Share/BreadCrumb'

const Product = props => {
  const { location } = props
  const request = location.pathname.replace(/\/+$/, '')
  const data = require('../product-data/' + path[request] + '.json')
  const headerImage = process.env.CLOUDINARY_URL + '/f_auto,w_1920/' + data.headerImage

  return (
    <Layout>
      <Header isProductPage={true} productMetaData={data.metaData} />
      <Menu />
      <div
        className="big-background-image pb-8 bg-lendis-dark-white bg-no-repeat bg-top lg:pb-40 pt-40 px-3"
        style={{ backgroundImage: `url(${headerImage})` }}
      >
        <div className="container mx-auto text-white">
          <h1 className="font-bold lg:text-6xl px-8 text-3xl mb-5">{data.headerTitle}</h1>
          <p className="font-semibold lg:text-2xl mb-10 mb-5 px-8 text-xl ">{data.subHeader}</p>
        </div>
        {data.breadcrumb && (
          <div className="container mx-auto px-2">
            <BreadCrumb breadCrumbItems={data.breadcrumb} />
          </div>
        )}
        {data.elements.map(element => {
          let Component = ''
          switch (element.component) {
            case 'BoxSection':
              Component = BoxSection
              break
            case 'CatalogView':
              Component = CatalogView
              break
            case 'RentBox':
              Component = RentBox
              break
            case 'ContentView':
              Component = ContentView
              break
            case 'ProcessBox':
              Component = ProcessBox
              break
            default:
              Component = <></>
              break
          }
          return <Component key={element.component} data={element.data} />
        })}
      </div>
      <Footer />
    </Layout>
  )
}

export default Product
