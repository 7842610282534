import React from 'react'
import parse, { domToReact } from 'html-react-parser'
import { Link } from 'gatsby'

const ContentView = ({ data }) => {
  const html = data.content
  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) return

      const attrs = []
      children.forEach(child => {
        switch (child.parent.name) {
          case 'p':
            attrs.push('p')
            break
          case 'h1':
            attrs.push('h1')
            break
          case 'h2':
            attrs.push('h2')
            break
          case 'h3':
            attrs.push('h3')
            break
          case 'h4':
            attrs.push('h4')
            break
          case 'h5':
            attrs.push('h5')
            break
          case 'h6':
            attrs.push('h6')
            break
          case 'ul':
            attrs.push('ul')
            break
          case 'li':
            attrs.push('li')
            break
          case 'ol':
            attrs.push('ol')
            break
          case 'a':
            attrs.push('a')
            break
          default:
            attrs.push('')
            break
        }
      })

      if (attrs.find(el => el === 'p')) {
        return <p className="py-2 leading-relaxed">{domToReact(children, options)}</p>
      }
      if (attrs.find(el => el === 'h1')) {
        return (
          <h1 className="font-bold leading-loose py-4 text-3xl">{domToReact(children, options)}</h1>
        )
      }
      if (attrs.find(el => el === 'h2')) {
        return (
          <h2 className="font-bold leading-loose py-4 text-2xl">{domToReact(children, options)}</h2>
        )
      }
      if (attrs.find(el => el === 'h3')) {
        return (
          <h3 className="font-bold leading-loose py-4 text-xl">{domToReact(children, options)}</h3>
        )
      }
      if (attrs.find(el => el === 'h4')) {
        return (
          <h4 className="font-bold leading-loose py-4 text-lg">{domToReact(children, options)}</h4>
        )
      }
      if (attrs.find(el => el === 'h5')) {
        return (
          <h5 className="font-bold leading-loose py-4 text-lg">{domToReact(children, options)}</h5>
        )
      }
      if (attrs.find(el => el === 'h6')) {
        return (
          <h6 className="font-bold leading-loose py-4 text-lg">{domToReact(children, options)}</h6>
        )
      }
      if (attrs.find(el => el === 'ul')) {
        return <ul className="px-12 py-4 list-disc">{domToReact(children, options)}</ul>
      }
      if (attrs.find(el => el === 'ol')) {
        return <ol className="px-12 py-4 list-decimal">{domToReact(children, options)}</ol>
      }
      if (attrs.find(el => el === 'li')) {
        return <li className="leading-relaxed">{domToReact(children, options)}</li>
      }
      if (attrs.find(el => el === 'a')) {
        if (
          attribs.href.includes('http') ||
          attribs.href.includes('https') ||
          attribs.href.includes('www')
        ) {
          return (
            <a className="text-lendis-main" href={attribs.href}>
              {domToReact(children, options)}
            </a>
          )
        } else {
          return (
            <Link className="text-lendis-main" to={attribs.href}>
              {domToReact(children, options)}
            </Link>
          )
        }
      }
    },
  }
  return <div className="container mx-auto bg-white px-6 xl:px-20 py-6">{parse(html, options)}</div>
}

export default ContentView
