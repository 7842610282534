import React from 'react'
import Image from '../Lendis/Image'
import { Link } from 'gatsby'
import Button from '../Share/Button'

const CatalogBox = ({ catalogs }) => {
  const widthCatalogLg = catalogs.length > 3 ? 3 : catalogs.length
  return (
    <div className="flex flex-wrap">
      {catalogs.map(catalog => {
        return (
          <div
            key={Math.random()}
            className={`w-full lg:w-1/${widthCatalogLg} ${
              catalogs.length >= 4 ? 'md:w-1/2' : ''
            } md:px-6 py-6 text-center`}
          >
            <Image
              className="lg:block hidden mx-auto w-48"
              size={`1/${widthCatalogLg}`}
              src={catalog.image.src}
              alt={catalog.image.alt}
            />
            <Image
              className="lg:hidden block mx-auto w-48"
              size="full"
              src={catalog.image.src}
              alt={catalog.image.alt}
            />
            <h3 className="text-2xl text-center pt-8">{catalog.title}</h3>
            {catalog.description && (
              <p className="text-center pt-4">
                {catalog.description.split('\n').map((item, idx) => {
                  return (
                    <span key={idx}>
                      {item}
                      <br />
                    </span>
                  )
                })}
              </p>
            )}
            {catalog.downloadLink && (
              <Link to={catalog.downloadLink}>
                <Button text={catalog.downloadTitle} green wFull className="mt-6 text-center" />
              </Link>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default CatalogBox
