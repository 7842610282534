import React from 'react'
import { Link } from 'gatsby'
import Button from '../Share/Button'
import Image from '../Lendis/Image'

const MediumViewBox = ({ image, title, description, buttonText, link, tripleView = false }) => {
  return (
    <div
      className={`relative mb-0 sm:mb-12 mt-0 bg-white flex flex-wrap items-end ${
        tripleView ? 'lg:h-40 xl:h-54 h-full' : 'lg:h-96 h-full'
      } ${link && 'lg:pb-56 xl:pb-0 md:pb-0'}`}
    >
      <div className="lg:absolute sm:relative flex sm:top-0 lg:w-3/4 w-full">
        <div className="top-0 mt-0 w-full">
          <Image
            alt={title}
            src={image}
            className={`object-cover w-full ${tripleView ? 'height-box-3' : 'height-box-2'}`}
            size="3/4"
          />
        </div>
      </div>
      <div
        className={`bg-white flex sm:absolute lg:w-8/12 w-full right-0 lg:top-0 bottom-0 mt-0 ${
          tripleView ? (link ? 'sm:mt-24 sm:h-32' : 'sm:mt-24 sm:h-12 lg:h-32') : 'sm:mt-48'
        }`}
      >
        <div className="relative pb-3 pt-3 px-4 w-full">
          <span className="font-medium text-xl pb-4">{title}</span>
          {description && <p className="leading-relaxed">{description}</p>}
          {link && (
            <Link to={link} className="flex w-full">
              <Button text={buttonText} arrow />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default MediumViewBox
