import React from 'react'
import Title from '../Share/Title'
import CatalogBox from './CatalogBox'
import { Link } from 'gatsby'
import Button from '../Share/Button'

const CatalogView = ({ data }) => {
  return (
    <div className="bg-white container mx-auto">
      <div className="px-12">
        <Title title={data.title} header={data.header} right titleSize="text-3xl" />
      </div>
      {data.box && <CatalogBox catalogs={data.catalogs} />}
      {(data.CTAText || data.CTAButton) && (
        <div className="flex flex-wrap justify-center text-center py-4">
          {data.CTAText && (
            <div className="w-full">
              <p>
                {data.CTAText.split('\n').map(function(item) {
                  return (
                    <span key={Math.random()}>
                      {item}
                      <br />
                    </span>
                  )
                })}
              </p>
            </div>
          )}
          <div className="sm:w-full">
            <Link to={data.CTATargetLink}>
              <Button text={data.CTAButton} green wFull className="mt-6 text-center" />
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}
export default CatalogView
