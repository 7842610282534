import React from 'react'
import MediumViewBox from './MediumViewBox'

const BoxView = ({ boxes }) => {
  const tripleView = boxes.length >= 6
  return (
    <div className="flex flex-wrap justify-center pt-4">
      {boxes.map(box => {
        return (
          <div
            key={Math.random()}
            className={`w-full lg:mb-0 md:px-6 ${box.description ? 'mb-12' : ''} ${
              tripleView ? 'lg:w-1/3' : box.description ? 'lg:w-1/2 lg:pb-12' : 'lg:w-1/2 '
            }`}
          >
            <MediumViewBox
              image={box.image}
              description={box.description}
              title={box.title}
              buttonText={box.button}
              link={box.buttonLink}
              tripleView={tripleView}
            />
          </div>
        )
      })}
    </div>
  )
}

export default BoxView
