import React from 'react'
import Image from '../Lendis/Image'
import { Link } from 'gatsby'
import Button from '../Share/Button'

const RentBox = ({ data }) => {
  return (
    <div className="bg-white container mx-auto">
      <div className="bg-white flex flex-wrap justify-center">
        <div className="w-full lg:w-1/2 px-4 lg:px-0">
          <div className="py-10 w-full">
            <h3 className="text-2xl font-semibold my-4">{data.header}</h3>
            <p className="leading-relaxed">{data.description}</p>
          </div>
          <div className="flex flex-wrap lg:pb-6">
            {data.price && (
              <div className="py-2 w-full lg:w-4/12">
                <div className="bg-lendis-light-gray pb-8 text-center pt-12 w-48">
                  <p className="pb-4 uppercase text-lendis-main">{data.workplace}</p>
                  <p>
                    ab <span className="font-bold text-3xl">{data.price} €</span>
                  </p>
                  <p>{data.period}</p>
                </div>
              </div>
            )}
            <div
              className={
                data.price ? 'pl-8 w-full lg:w-8/12 lg:pl-12 xl:pl-8' : ' w-full lg:w-10/12'
              }
            >
              {data.bulletPoints.map(bullet => {
                return (
                  <div
                    key={Math.random()}
                    className="items-center flex text-left leading-loose py-2"
                  >
                    <Image className="w-8" noChange src={bullet.icon} alt="icon-1" />
                    <p className="pl-5 ">{bullet.text}</p>
                  </div>
                )
              })}
            </div>

            {data.hasLink && (
              <div className=" w-full lg:w-10/12 ">
                <div className="items-center flex text-left py-2">
                  <Link to={data.link.url}>
                    <Button arrow={true} text={data.link.button} />
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RentBox
