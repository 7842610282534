import React from 'react'
import Title from '../Share/Title'
import BoxView from './BoxView'
import { Link } from 'gatsby'
import Button from '../Share/Button'

const BoxSection = ({ data }) => {
  return (
    <div className="container mx-auto bg-white">
      {data.boxingHeader && (
        <div className="px-12">
          <Title
            bgColor="bg-white"
            title={data.boxingHeader}
            header={data.boxingSubHeader}
            right
            titleSize="text-3xl"
          />
        </div>
      )}
      <BoxView boxes={data.boxes} />

      {data.CTAText && (
        <div className="flex flex-wrap justify-center text-center py-4">
          <div className="w-full">
            <p>
              {data.CTAText.split('\n').map(function(item) {
                return (
                  <span key={Math.random()}>
                    {item}
                    <br />
                  </span>
                )
              })}
            </p>
          </div>
          <div className="sm:w-full">
            <Link to={data.CTATargetLink}>
              <Button text={data.CTAButton} green wFull className="mt-6 text-center" />
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default BoxSection
