import React from 'react'
import SquareSection from '../Share/SquareSection'

const ProcessBox = ({ data }) => {
  return (
    <SquareSection
      smallTitle={data.process_title}
      title={data.process_big_title}
      content={[
        {
          title: data.process_title_1,
          description: data.process_description_1,
          image: data.process_icon_1,
          imageSize: '55',
          width: '3',
          className: '',
          numberTitle: '01',
        },
        {
          title: data.process_title_2,
          description: data.process_description_2,
          image: data.process_icon_2,
          imageSize: '55',
          width: '3',
          className: '',
          numberTitle: '02',
        },
        {
          title: data.process_title_3,
          description: data.process_description_3,
          image: data.process_icon_3,
          imageSize: '55',
          width: '3',
          className: '',
          numberTitle: '03',
        },
      ]}
    />
  )
}

export default ProcessBox
